import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination} from 'swiper/modules';
import {motion} from "framer-motion";
import {fadeIn} from "../../motion/motion";


const Testimonials = () => {
  return (
    <div className="md:py-18 py-8">
      <div className="container px-4  mx-auto text-white">
        <h2 className="text-3xl text-black mb-4 text-center font-bold tracking-wider">
          Testimonials
        </h2>
        <motion.div
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true }}
          variants={fadeIn("up", "", 0.25, 1)}
          className="flex lg:w-2/3 bg-[#09091c] text-white rounded shadow-lg mx-auto p-4"
        >
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            mousewheel={true}
            className="mySwiper"
          >
            <SwiperSlide className="p-10 ">
              <h2 className="text-3xl text-white mb-2 text-center font-bold tracking-wider">
                John: CEO @ LLC Engineering
              </h2>
              <p className="text-lg font-semibold text-center tracking-wider">
                ALLCON LLC is a Hispanic, Female owned general contracting firm.
                We offer a unique blend of self-performing electrical and
                carpentry disciplines. Our team has the ability to take your
                project from the planning stage to final completion.
              </p>
            </SwiperSlide>
            <SwiperSlide className="p-10 ">
              <h2 className="text-3xl text-white mb-2 text-center font-bold tracking-wider">
                John: CEO @ LLC Engineering
              </h2>
              <p className="text-lg font-semibold text-center tracking-wider">
                ALLCON LLC is a Hispanic, Female owned general contracting firm.
                We offer a unique blend of self-performing electrical and
                carpentry disciplines. Our team has the ability to take your
                project from the planning stage to final completion.
              </p>
            </SwiperSlide>
            <SwiperSlide className="p-10 ">
              <h2 className="text-3xl text-white mb-2 text-center font-bold tracking-wider">
                John: CEO @ LLC Engineering
              </h2>
              <p className="text-lg font-semibold text-center tracking-wider">
                ALLCON LLC is a Hispanic, Female owned general contracting firm.
                We offer a unique blend of self-performing electrical and
                carpentry disciplines. Our team has the ability to take your
                project from the planning stage to final completion.
              </p>
            </SwiperSlide>
          </Swiper>
        </motion.div>
      </div>
    </div>
  );
}

export default Testimonials;




