import { motion } from "framer-motion";
import { fadeIn } from "../../motion/motion";

const Builder = () => {
  return (
    <div className="container mx-auto flex justify-center items-center min-h-[500px] px-2 py-8 md:py-24">
      <motion.div
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.25, 1)}
        className="grid grid-cols-1 gap-3 gap-y-6 lg:grid-cols-2"
      >
        <div className="flex px-4 gap-3 lg:order-1 order-2 flex-col justify-start items-start flex-1">
          <h2 className="text-3xl font-bold tracking-wider">Our Mission</h2>
          <p className="text-lg font-semibold tracking-wider">
            At ALLCON, our mission is to construct not just buildings, but
            legacies. We strive to deliver projects that are a harmonious blend
            of aesthetic beauty, structural integrity, and sustainability. Our
            focus is on creating spaces that enhance communities and stand the
            test of time.
          </p>
          <h2 className="text-3xl font-bold tracking-wider">Our Vision</h2>
          <p className="text-lg font-semibold tracking-wider">
            We envision ALLCON, as a beacon in the construction industry,
            synonymous with quality, innovation, and customer satisfaction. Our
            goal is to be the go-to company for clients who seek construction
            services that combine traditional craftsmanship with modern
            technology.
          </p>
        </div>
        <div className="flex px-4 h-full flex-1 lg:order-2 order-1">
          <img
            className="w-full h-full object-contain object-center rounded-t"
            src="https://cdn.pixabay.com/photo/2012/07/06/20/23/excavator-51665_1280.jpg"
            alt=""
          />
        </div>
      </motion.div>
    </div>
  );
};

export default Builder;
