import AboutTitle from "./AboutTitle";
import Builder from "./Builder";
import Choose from "./Choose";
import Testimonials from "./Testimonials";
import Values from "./Values";


export default function About() {
  return (
    <>
   <AboutTitle />
   <Builder />
   <Values />
   <Choose />
   <Testimonials />
   </>
  );
}
