import React from 'react';
import Card from "../Reuse/Card";
import { fadeIn } from '../../motion/motion';
import { motion } from "framer-motion";

const blogLists = [
  {
    id: 1,
    title: "General Contracting",
    content:
      "As your trusted general contractor, we manage all aspects of your construction project. From coordinating with subcontractors to ensuring compliance with all regulations, we handle it all, ensuring a hassle-free experience for you.",
    image:
      "https://cdn.pixabay.com/photo/2017/08/03/21/37/construction-2578410_1280.jpg",
    link: "",
  },
  {
    id: 2,
    title: "Architectural Design",
    content:
      "Our architectural design services are where creativity meets functionality. We work with you to create designs that are not just visually stunning but also practical, sustainable, and in line with your vision.",
    image:
      "https://cdn.pixabay.com/photo/2015/11/17/18/59/architecture-1048092_1280.jpg",
    link: "",
  },
  {
    id: 3,
    title: "Engineering and Structural Integrity",
    content:
      "Our engineering services are the backbone of our construction projects. We use cutting-edge technology and innovative methods to ensure that every structure we build is safe, durable, and built to last.",
    image:
      "https://cdn.pixabay.com/photo/2019/02/06/16/32/architect-3979490_1280.jpg",
    link: "",
  },
  {
    id: 6,
    title: "Project Management",
    content:
      "Our project management services ensure that your project is completed on time, within budget, and to the highest standards. We oversee every detail, from planning to execution, ensuring a smooth and successful project delivery.",
    image:
      "https://cdn.pixabay.com/photo/2019/09/27/08/54/knowledge-train-4507900_1280.jpg",
    link: "",
  },
  {
    id: 4,
    title: "Interior Design and Renovation",
    content:
      "Transform your space with our interior design and renovation services. Whether it's a home, office, or commercial space, we create interiors that are stylish, functional, and reflective of your personal style.",
    image:
      "https://cdn.pixabay.com/photo/2014/12/27/14/37/living-room-581073_1280.jpg",
    link: "",
  },
  {
    id: 5,
    title: "Sustainable and Green Building",
    content:
      "Embrace sustainability with our green building practices. We are committed to reducing the environmental impact of our projects, using eco-friendly materials and techniques to create energy-efficient and sustainable buildings.",
    image:
      "https://cdn.pixabay.com/photo/2016/06/24/22/24/solar-panels-1477987_1280.jpg",
    link: "",
  },
];

const ServiceType = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.25, 1)}
        className="flex flex-col gap-3"
      >
        <h2 className="text-3xl font-bold tracking-wider">
          Experience the ALLCON Difference
        </h2>
        <p className="text-lg font-semibold tracking-wider">
          Your one-stop destination for a wide array of construction services.
          We offer bespoke solutions, tailored to meet the unique requirements
          of each client. Our services are a blend of quality, innovation, and
          efficiency.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-3  gap-y-6 gap-x-4 mt-8">
          {blogLists?.slice(0, 3).map((blog) => {
            const { id, title, content, image } = blog;
            return (
              <Card
                key={id}
                title={title}
                content={content}
                image={image}
                opacity={"opacity-0"}
              />
            );
          })}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-4 mt-8">
          {blogLists?.slice(3, 4).map((blog) => {
            const { id, title, content, image } = blog;
            return (
              <Card
                key={id}
                title={title}
                content={content}
                image={image}
                opacity={"opacity-0"}
              />
            );
          })}
          <div className="flex md:flex-row flex-col gap-3 gap-y-6 h-fit">
            {blogLists?.slice(4).map((blog) => {
              const { id, title, content, image } = blog;
              return (
                <Card
                  key={id}
                  title={title}
                  content={content}
                  image={image}
                  opacity={"opacity-0"}
                />
              );
            })}
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default ServiceType
