import {motion} from "framer-motion";
import { fadeIn } from "../../motion/motion";
import Card from "../Reuse/Card";
import { useNavigate } from "react-router-dom";
import { blogLists } from "../../constant";

const Blog = () => {

  const navigate = useNavigate();

  return (
    <div className="w-full bg-[#09091c]">
      <motion.div 
      initial= "hidden"
      whileInView={"show"}
      viewport={{once: true}}
      variants={fadeIn("up", "", 0.25, 1)}
      className="container px-4 mx-auto py-8 md:py-24">
        <div className="flex flex-col">
          <h2 className="text-3xl text-white font-bold tracking-wider">
            Latest industries trends & blogs.
          </h2>
          <p className="text-lg text-white font-semibold tracking-wider mt-2">
            Providing expert construction Management and building solutions.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-6 gap-x-4 mt-8">
          {blogLists?.map((blog) => {
            const { id, title, content, image } = blog;
            return (
              <Card
                key={id}
                title={title}
                content={content}
                image={image}
                click = {()=>navigate(`blogs/${id}`)}
                opacity={"opacity-25"}
              />
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

export default Blog;
