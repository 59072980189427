import { motion } from "framer-motion";
import { fadeIn } from "../../motion/motion";

const backgroundImageUrl =
  "https://images.unsplash.com/photo-1541888946425-d81bb19240f5?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y29uc3RydWN0aW9ufGVufDB8fDB8fHww";

export default function AboutTitle() {
  return (
    <div className="w-full bg-black h-auto">
      <div className="w-full relative py-8 md:min-h-[85vh] flex items-center justify-center">
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover opacity-50 bg-black"
          style={{ backgroundImage: `url(${backgroundImageUrl})` }}
        >
          <span className="w-full h-full absolute left-0 opacity-25 bg-black"></span>
        </div>
        <div className="container relative mx-auto pt-10">
          <motion.div
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
            variants={fadeIn("up", "", 0.25, 1)}
            className="flex px-8 flex-col items-start justify-center"
          >
            <h1 className="text-white font-bold text-3xl sm:text-5xl text-left">
              Paving the Path <br />
               to  Architectural <br /> Excellence
            </h1>
            <p className="text-2xl font-normal tracking-wider max-w-xl text-white">
              Since our inception, we have set out to redefine the construction
              landscape, driven by a mission to deliver projects that not only
              meet but exceed expectations. Our foundation is built on the
              cornerstones of integrity, innovation, and dedication.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
