import Photo from '../Reuse/Photo';
import ServiceList from './ServiceList';
import ServiceType from './ServiceType';

const Service = () => {

    const url =
        "https://cdn.pixabay.com/photo/2021/08/30/18/33/worker-6586718_1280.jpg";

  return (
    <div>
      <Photo height={"md:min-h-[60vh] h-[300px]"} url={url} title={" Our Services"} />
      <ServiceType />
      <ServiceList />
    </div>
  );
}

export default Service
