import {motion} from "framer-motion";
import { fadeIn } from "../../motion/motion";
import Choose from "../About/Choose";

const End = () => {
  return (
    <>
      <motion.div
        initial={"hidden"}
        whileInView={"show"}
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.25, 1)}
        className="container mx-auto py-8 md:pt-12"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div className="flex flex-1 flex-col gap-6 p-6 bg-[#09091c] rounded-t-xl cursor-pointer hover:bg-teal-400 transition-all duration-500 ease-in-out">
            <h2 className="text-3xl text-white font-bold tracking-wider">
              Your Vision, Our Blueprint
            </h2>
            <p className="text-lg text-white font-semibold tracking-wider">
              Whether it's a cozy family home, a state-of-the-art office
              building, or a luxury commercial space, we tailor our services to
              fit your specific needs and desires. Your dream project is our
              canvas.
            </p>
          </div>
          <div className="flex flex-1 flex-col gap-6 p-6 bg-[#09091c] rounded-t-xl cursor-pointer hover:bg-teal-400 transition-all duration-500 ease-in-out">
            <h2 className="text-3xl text-white font-bold tracking-wider">
              Eco-Friendly Construction
            </h2>
            <p className="text-lg text-white font-semibold tracking-wider">
              We are committed to eco-friendly practices in all our projects,
              ensuring that we not only build for today but also contribute
              positively to the future. Our sustainable building methods reduce
              environmental impact and create healthier, more efficient spaces
              for our clients and the community.
            </p>
          </div>
        </div>
      </motion.div>
      <div className="container mx-auto pb-8 px-4">
        <Choose />
      </div>
    </>
  );
}

export default End;
