import React, { useEffect, useState } from "react";
import { NavLink as Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { fadeIn } from "../motion/motion";
import {motion }from "framer-motion"
import {ReactComponent as Img}  from "../alcon/svg/logo-no-background.svg";

const Links = ({ handleToggleClick, name, to }) => {
  let style = "relative p-3  cursor-pointer lg:px-3 cursor-pointer  btn4 overflow-hidden md:mx-2  rounded hover:text-teal-300 transition-colors duration-300 transition duration-500 ease-in-out font-semibold";
  return (
    <Link
      onClick={handleToggleClick}
      to={to}
      className={({ isActive }) =>
        isActive ? `${style} text-teal-400` : `${style} text-white`
      }
    >
      {name}
    </Link>
  );
};

export default function Navbar() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  const handleToggleClick = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  return (
    <nav
      className={`${
        isScrolled && "bg-[#09091c] duration-300 transition ease-in-out"
      }   py-3 md:py-4 z-20 w-full absolute md:fixed`}
      id="home"
    >
      <div className="container mx-auto md:flex md:items-center">
        <div className="flex justify-between items-center">
          <Link
            activeclass="active"
            to="/"
            className="flex p-3 items-center font-bold cursor-pointer  text-xl text-teal-300"
          >
            <Img className="w-[120px] h-[30px] fill-teal-400"/>
            {/* <img
              src={
                "https://allconllc.net/wp-content/uploads/2023/08/image001.png"
              }
              className="w-[180px] absolute top-2 h-[55px] md:h-[65px] fill-teal-300"
              alt=""
            /> */}
          </Link>
          <button
            onClick={handleToggleClick}
            className="px-3 py-1 rounded transition-all ease-in-out
               duration-500  text-gray-600 text-teal-300 md:hidden"
          >
            {isCollapsed ? (
              <AiOutlineMenu className="fill-teal-300" size={"30px"} />
            ) : (
              <RxCross2 size={"30px"} />
            )}
          </button>
        </div>

        <motion.div
          initial={"hidden"}
          whileInView={"show"}
          variants={fadeIn("up", "", 0.25, 0.75)}
          viewport={{ once: isCollapsed ? false : true }}
          className={`${isCollapsed ? "hidden" : "flex"}
            md:flex bg-black md:bg-transparent absolute right-[20px] md:static w-[170px]  md:w-auto px-2 p-6 md:p-0  rounded flex-col md:flex-row md:ml-auto mt-3 md:mt-0`}
        >
          <Links
            handleToggleClick={handleToggleClick}
            to={"/about"}
            name={"Who We Are"}
          />

          <Links
            handleToggleClick={handleToggleClick}
            to={"/services"}
            name="What We Do"
          />
          <Links
            handleToggleClick={handleToggleClick}
            to={"/work"}
            name="Works"
          />
          <Links
            handleToggleClick={handleToggleClick}
            to={"/career"}
            name="Careers"
          />

          <Link
            to={"/contact"}
            onClick={handleToggleClick}
            className="border border-indigo-600 p-3 w-[120px] cursor-pointer lg:px-6 cursor-pointer  md:mx-2 text-white
             font-semibold rounded hover:bg-teal-400 ml-2 transition-all ease-in-out duration-300"
          >
            Contact
          </Link>
        </motion.div>
      </div>
    </nav>
  );
}
