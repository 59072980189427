import Layout from "./Layout";
import {Route, Routes} from "react-router-dom";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Service from "./components/Services/Service";
import Work from "./components/Work/Work";
import Career from "./components/Career/Career";
import Contact from "./components/Contact/Contact";
import ErrorPage from "./components/ErrorPage";
import Page from "./components/Blog/Page";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path= {"/" || "/home"} element={ <Layout> <Home/> </Layout>} />  
        <Route path="/services" element={ <Layout> <Service/> </Layout>} />  
        <Route path="/about" element={ <Layout> <About/> </Layout>} />  
        <Route path="/contact" element={ <Layout> <Contact/> </Layout>} /> 
        <Route path="/career" element={ <Layout> <Career/> </Layout>} />  
        <Route path="/work" element={ <Layout> <Work/> </Layout>} />  
          <Route path="/blogs/:id" element={ <Layout> <Page/> </Layout>} />  
          <Route path="*" element={ <Layout> <ErrorPage/> </Layout>} />  
      </Routes>
    </div>
  );
}

export default App;
