import React from "react";
import { Link } from "react-router-dom";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillInstagram,
} from "react-icons/ai";



const  NavLink = ({link, scrollToTop, name}) =>{
  return (
    <Link
      className="cursor-pointer w-fit hover:text-teal-300 underline-offset-4 text-lg text-white font-semibold hover:underline transition-all ease-in-out duration-5000"
      activeclass="active"
      to= {link}
      onClick={scrollToTop}
    >
      {name}
    </Link>
  );
}

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const now = new Date();
  const year = now.getFullYear();

  return (
    <footer className="bg-[#09091c] text-white flex flex-col justify-center items-center pt-12 pb-8 px-4 min-h-[60vh]">
      <div className="container border-l border-r border-indigo-600 pb-5 overflow-hidden flex flex-col lg:flex-row justify-between mx-auto px-4">
        <div className="flex flex-col px-4">
          <h2 className="block mr-2 w-30 text-2xl font-serif font-bold">
            ALLcon
            <br />
            Building home
          </h2>
          <button
            // onClick={() => setOpen(!open)}
            className="text-white w-fit font-semibold mt-4 p-3 bg-[#09091c] transition-all ease-in-out duration-300 border-indigo-600 hover:bg-teal-400 border px-6 rounded"
          >
            Get In Touch
          </button>
        </div>
        <div className="w-full md:w-3/4 lg:w-1/2 flex-col sm:flex-row gap-4 px-4 justify-center flex text-sm mt-6 lg:mt-0">
          <div className="flex flex-col  gap-2 w-full">
            <span className="text-white uppercase font-medium tracking-wider">
              Useful Links
            </span>
            <NavLink link="/" scrollToTop={scrollToTop} name={"Home"} />

            <NavLink
              link="/about"
              scrollToTop={scrollToTop}
              name={"What We Are"}
            />

            <NavLink
              link="/career"
              scrollToTop={scrollToTop}
              name={"Join Us"}
            />

            <NavLink
              link="/work"
              scrollToTop={scrollToTop}
              name={"What We Do"}
            />
          </div>

          <div className="flex flex-col  gap-2 w-full">
            <span className="text-white uppercase font-medium tracking-wider">
              Company Links
            </span>

            <NavLink
              link="/"
              scrollToTop={scrollToTop}
              name={"Policy Policy"}
            />

            <NavLink
              link="/about"
              scrollToTop={scrollToTop}
              name={"Term Of Use"}
            />
          </div>

          <div className="flex flex-col w-full text-gray-500">
            <div className="uppercase font-medium text-white tracking-wide">
              Follow Us
            </div>
            <div className="flex mt-2">
              <Link
                activeclass="active"
                target="_blank"
                to=""
                className="flex items-center text-white mr-6"
              >
                <AiFillFacebook
                  size={"30px"}
                  className="hover:fill-teal-300 "
                />
              </Link>
              <Link
                activeclass="active"
                target="_blank"
                to=""
                className="flex items-center text-white mr-6"
              >
                <AiFillTwitterSquare
                  size={"30px"}
                  className="hover:fill-teal-300 "
                />
              </Link>
              <Link
                activeclass="active"
                target="_blank"
                to=""
                className="flex items-center text-white"
              >
                <AiFillInstagram
                  size={"30px"}
                  className="hover:fill-teal-300 "
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4 container mx-auto mt-4 pt-6 text-gray-400 border-t border-indigo-600 flex flex-col md:flex-row justify-center items-center">
        <div>
          Powered By{" "}
          <a
            className="text-white"
            target="_blank"
            rel="noreferrer"
            href="https://www.yetitech.com.au"
          >
            Yeti Tech
          </a>{" "}
          © {year}.
        </div>
      </div>
    </footer>
  );
}

