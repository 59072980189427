import {motion} from "framer-motion";
import { fadeIn } from "../../motion/motion";
import Card from "../Reuse/Card";

const projects = [
  {
    url: "https://cdn.pixabay.com/photo/2017/10/28/15/44/new-home-2897352_1280.jpg",
    text: "project",
  },
  {
    url: "https://cdn.pixabay.com/photo/2016/04/28/15/50/construction-site-1359136_1280.jpg",
    text: "project",
  },
  {
    url: "https://cdn.pixabay.com/photo/2016/06/24/19/27/construction-site-1477687_1280.jpg",
    text: "project",
  },
  {
    url: "https://cdn.pixabay.com/photo/2016/05/21/21/39/housebuilding-1407499_1280.jpg",
    text: "project",
  },

  {
    url: "https://cdn.pixabay.com/photo/2016/05/21/21/39/housebuilding-1407499_1280.jpg",
    text: "project",
  },
  {
    url: "https://cdn.pixabay.com/photo/2018/05/11/11/29/construction-3390318_1280.jpg",
    text: "project",
  },
  {
    url: "https://cdn.pixabay.com/photo/2017/08/11/11/51/site-2630480_1280.jpg",
    text: "project",
  },
  {
    url: "https://cdn.pixabay.com/photo/2018/04/02/18/38/construction-3284735_1280.jpg",
    text: "project",
  },
  {
    url: "https://cdn.pixabay.com/photo/2018/04/02/18/38/construction-3284735_1280.jpg",
    text: "project",
  },
];

const Projects = () => {
  return (
    <div className="container mx-auto py-8 md:pt-8 px-4">
      <motion.div
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.25, 1)}
        className="flex flex-col gap-3 text-black mb-6 w-full font-bold px-4 justify-center items-center py-8"
      >
        <h2 className="text-3xl font-bold text-center tracking-wider">
          Shaping cities, reimagine communities, <br />
           and create place for everyone.
        </h2>
        <p className="text-lg max-w-3xl text-center font-semibold tracking-wider">
          Your one-stop destination for a wide array of construction services.
          We offer bespoke solutions, tailored to meet the unique requirements
          of each client. Our services are a blend of quality, innovation, and
          efficiency.
        </p>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.25, 1)}
        className="flex flex-col md:flex-row gap-3"
      >
        <div className="flex w-full h-fit">
          {projects?.slice(0, 1).map((project, index) => {
            const { url, text } = project;
            return (
              <Card key={index} image={url} opacity={"opacity-0"} text={text} />
            );
          })}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 h-fit w-full gap-5">
          {projects?.slice(1, 5).map((project, index) => {
            const { url, text } = project;
            return (
              <Card key={index} image={url} opacity={"opacity-0"} text={text} />
            );
          })}
        </div>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.25, 1)}
        className="flex flex-col md:flex-row gap-3"
      >
        <div className="grid grid-cols-1 pt-4 md:pt-0 lg:grid-cols-2 h-fit w-full gap-5">
          {projects?.slice(5, 9).map((project, index) => {
            const { url, text } = project;
            return <Card key={index} image={url} opacity={"opacity-0"} text={text} />;
          })}
        </div>
        <div className="flex w-full h-fit mt-2">
          {projects?.slice(0, 1).map((project, index) => {
            const { url, text } = project;
            return <Card key={index} image={url} opacity={"opacity-0"} text={text}  />;
          })}
        </div>
      </motion.div>
    </div>
  );
}

export default Projects
