import React from "react";
import Photo from "../Reuse/Photo";
import { FaFacebook, FaLinkedin, FaTwitter, FaInstagramSquare  } from "react-icons/fa";

const Contact = () => {
  const url =
    "https://images.unsplash.com/photo-1466096115517-bceecbfb6fde?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNvbnRhY3R8ZW58MHx8MHx8fDA%3D";
  return (
    <div>
      <Photo height={"min-h-[60vh]"} url={url} title={"Get In Touch"} />
      <div className="container mx-auto py-24 px-4">
        <h2 className="text-3xl mb-6 font-bold tracking-wider">
          WE'RE READY, LET'S TALK.
        </h2>
        <div className="flex flex-col gap-3 lg:flex-row">
          <form className="mx-auto w-full flex flex-1 flex-col gap-4" action="">
            <div className="flex gap-2 flex-col">
              <label className="text-xl font-semibold" htmlFor="name">
                First Name:
              </label>
              <input
                className="p-3 transition-all duration-500 ease-in-out outline-none focus:outline-black text-xl rounded"
                type="text"
                id="name"
                name="name"
                placeholder="John"
                required
              />
            </div>
            <div className="flex gap-2 flex-col">
              <label className="text-xl font-semibold" htmlFor="name">
                Last Name:
              </label>
              <input
                className="p-3 transition-all duration-500 ease-in-out outline-none focus:outline-black text-xl rounded"
                type="text"
                id="name"
                name="name"
                placeholder="Smith"
                required
              />
            </div>
            <div className="flex gap-2 flex-col">
              <label className="text-xl font-semibold" htmlFor="name">
                Email:
              </label>
              <input
                className="p-3 transition-all duration-500 ease-in-out outline-none focus:outline-black text-xl rounded"
                type="email"
                id="name"
                name="name"
                placeholder="Johnsmith@example.com"
                required
              />
            </div>
            <div className="flex gap-2 flex-col">
              <label className="text-xl font-semibold" htmlFor="name">
                Message:
              </label>
              <textarea
                className="p-3 transition-all duration-500 ease-in-out outline-none focus:outline-black text-xl rounded"
                type="text"
                id="name"
                rows={"8"}
                name="name"
                required
              />
            </div>
            <button className="text-white w-[220px] font-semibold mt-4 p-3 bg-[#09091c] transition-all ease-in-out duration-300 border-indigo-600 hover:bg-teal-400 border px-6 rounded">
              Send Message
            </button>
          </form>
          <div className="flex flex-1 flex-col pt-8 md:pl-6 gap-3">
            <h2 className="text-2xl font-bold tracking-wider">CONTACT INFO</h2>
            <h2 className="text-2xl font-bold tracking-wider">Address</h2>
            <p className="text-lg">12704 W Arden Place Butler, WI 53007</p>
            <h2 className="text-2xl font-bold tracking-wider">Call Us</h2>
            <p className="text-lg">414-389-9000</p>
            <h2 className="text-2xl font-bold tracking-wider">Follow Us</h2>
            <div className="flex gap-3">
              <FaFacebook
                size={25}
                className="hover:fill-teal-400 cursor-pointer transition duration-500 ease-in-out"
              />
              <FaInstagramSquare
                size={25}
                className="hover:fill-teal-400 cursor-pointer transition duration-500 ease-in-out"
              />
              <FaLinkedin
                size={25}
                className="hover:fill-teal-400 cursor-pointer transition duration-500 ease-in-out"
              />
              <FaTwitter
                size={25}
                className="hover:fill-teal-400 cursor-pointer transition duration-500 ease-in-out"
              />
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2895465.992666849!2d-92.20879205249845!3d44.87036591189727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52a8f40f9384e3af%3A0xf2d5d5b8f88649d6!2sWisconsin%2C%20USA!5e0!3m2!1sen!2sau!4v1704888575328!5m2!1sen!2sau"
              className="w-full"
              height="250"
              title="Alcon LLC address"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
