import React from 'react';
import Slider from './Slider';
import Quotation from './Quotation';
import Blog from './Blog';
import End from './End';


const Home = () => {
  return (
    <div>
        <Slider/>
        <Quotation />
        <Blog/>
        <End />
    </div>
  )
}

export default Home     