import { fadeIn } from "../../motion/motion";
import { motion } from "framer-motion";

const lists = [
  {
    title: "Integrity and Ethics",
    text: "We conduct our business with the highest ethical standards, ensuring honesty and fairness in all our dealings.",
  },
  {
    title: "Expert Craft",
    text: "We take pride in our work, ensuring that every project we undertake is a testament to our skill and attention to detail.",
  },
  {
    title: "Innovation",
    text: " We embrace change and innovation, constantly seeking out new methods and technologies to improve our services.",
  },
  {
    title: "Customer Focus",
    text: "We are committed to understanding and meeting our clients' needs, ensuring their visions are realized to the fullest.",
  },
];

const Values = () => {
  return (
    <div className="bg-[#09091c] py-8 mb-8">
      <motion.div
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.25, 1)}
        className="container mx-auto px-4"
      >
        <h2 className="text-3xl text-white mb-4 text-start font-bold tracking-wider">
          Our Core Values
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
          {lists.map((list, index) => {
            const { text, title } = list;
            return (
              <div
                key={index}
                className="flex shadow shadow-lg p-4 text-white border rounded flex-col justify-center items-start gap-3"
              >
                <h2 className="text-3xl mb-4 text-left font-bold tracking-wider">
                  {title}
                </h2>
                <p className="text-lg  font-semibold text-left tracking-wider">
                  {text}
                </p>
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

export default Values;
