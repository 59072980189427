import React from "react";

const Dialogue = ({ click }) => {

  const handleSubmit = () =>{

  }

  return (
    <div className="fixed z-10 overflow-y-auto top-0 w-full left-0 ">
      <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className="inline-block align-center bg-white rounded-lg text-left py-6 overflow-x-hidden overflow-y-scroll
         shadow-xl transform transition-all sm:my-8 sm:align-middle w-full px-4 sm:w-[500px]"
        >
          <form className="p-4 md:p-5 overflow-scroll">
            <div className="grid gap-4 mb-4 grid-cols-2">
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="bg-gray-50 border text-gray-900 text-sm outline-none rounded-lg focus:border-black block w-full p-2.5"
                  placeholder="Type your name"
                  required
                />
              </div>
              <div class="col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Company Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="bg-gray-50 border text-gray-900 text-sm outline-none rounded-lg focus:border-black block w-full p-2.5"
                  placeholder="Type your email"
                  required
                />
              </div>
              <div class="col-span-2 sm:col-span-1">
                <label
                  htmlFor="price"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Budget
                </label>
                <input
                  type="number"
                  name="price"
                  min={0}
                  id="price"
                  className="bg-gray-50 border text-gray-900 text-sm outline-none rounded-lg focus:border-black block w-full p-2.5"
                  placeholder="$2999"
                  required
                />
              </div>
              <div className="col-span-2 sm:col-span-1">
                <label
                  for="category"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Category
                </label>
                <select
                  id="category"
                  className="bg-gray-50  border text-gray-900 text-sm rounded-lg outline-none focus:border-black block w-full p-2.5"
                  required
                >
                  <option selected="">Please Select</option>
                  <option value="TV">Construction</option>
                  <option value="TV">Electricity</option>
                  <option value="PC">Housing</option>
                  {/* <option value="GA">Gaming/Console</option>
                            <option value="PH">Phones</option> */}
                </select>
              </div>
              <div class="col-span-2">
                <label
                  for="description"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Product Description
                </label>
                <textarea
                  id="description"
                  rows="6"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 outline-none focus:border-black"
                  placeholder="Write product description here"
                  required
                ></textarea>
              </div>
            </div>
            <button
              onClick={handleSubmit}
              className="text-white inline-flex items-center bg-[#09091c]  hover:bg-teal-400 border border-indigo-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-teal-300 dark:hover:bg-teal-300 dark:focus:ring-teal-300"
            >
              Get A Quote
            </button>
          </form>

          <button
            className="py-2 absolute top-[12px] right-0 px-4 bg-red-600 text-white rounded mr-2"
            onClick={click}
          >
            <i className="fas fa-times"></i> X
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dialogue;
