import Photo from "../Reuse/Photo";
import {motion} from "framer-motion";
import { fadeIn } from "../../motion/motion";

const Career = () => {
  const url =
    "https://images.unsplash.com/photo-1504805572947-34fad45aed93?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGNhcmVlcnxlbnwwfHwwfHx8MA%3D%3D";

  return (
    <div>
      <Photo height={"min-h-[60vh]"} url={url} title={" Our Career"} />
      <div className="container mx-auto py-6 md:py-24 px-4">
        <motion.div
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true }}
          variants={fadeIn("up", "", 0.25, 1)}
          className="grid grid-cols-1 gap-8 md:grid-cols-2 py-8 md:py-18"
        >
          <div className="flex flex-1 gap-3 flex-col">
            <h2 className="text-3xl font-bold tracking-wider">
              Join Our Team – Building Excellence Together
            </h2>
            <p className="text-lg font-semibold tracking-wider">
              A place where your skills, creativity, and passion can contribute
              to shaping the future of construction. At Allcon LLC, we believe
              that our greatest asset is our people. We are always on the
              lookout for talented, enthusiastic, and innovative individuals to
              join our dynamic team. If you are ready to build a career that's
              as sturdy and promising as the structures we create, you've come
              to the right place.
            </p>
            <p className="text-lg font-semibold tracking-wider">
              Please send your resume @{" "}
              <span
                onClick={() => (window.location = "mailto:yourmail@domain.com")}
                className="text-teal-400 cursor-pointer underline underline-offset-4"
              >
                {" "}
                careers@alconllc.com
              </span>
              . Our hiring team will access your resume and will reach out to
              you if you meet our requirements.
            </p>
          </div>
          <div className="flex flex-1 flex-col">
            <img
              className="w-full h-full object-containe object-cover"
              src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTA2fHxjYXJlZXJ8ZW58MHx8MHx8fDA%3D"
              alt="alcon llc staff"
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Career;
