import { motion } from "framer-motion";
import { fadeIn } from "../../motion/motion";

const Choose = () => {
  return (
    <div className="">
      <div className="container mx-auto flex justify-center items-center min-h-[500px] px-2">
        <motion.div
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true }}
          variants={fadeIn("up", "", 0.25, 1)}
          className="flex px-4 gap-4  lg:order-1 order-2 flex-col justify-start items-start flex-1"
        >
          <h2 className="text-3xl font-bold tracking-wider">
            Choose Excellence at ALLCON
          </h2>
          <p className="text-lg font-semibold tracking-wider">
            <span className="font-semibold text-2xl">
              Experienced Professionals
            </span>
            <br /> Our team brings decades of combined experience, ensuring your
            projects are in capable hands.
          </p>
          <p className="text-lg font-semibold tracking-wider">
            <span className="font-semibold text-2xl">
              Client-Centric Approach
            </span>
            <br />
            We prioritize your vision and requirements, delivering customized
            solutions that exceed expectations.
          </p>
          <p className="text-lg font-semibold tracking-wider">
            <span className="font-semibold text-2xl">
              Innovative Techniques
            </span>
            <br />
            Utilizing the latest technology and methods, we guarantee efficiency
            and quality in every project.
          </p>
          <p className="text-lg font-semibold tracking-wider">
            <span className="font-semibold text-2xl">Commitment to Safety</span>
            <br />
            Safety is paramount in all our operations, protecting both our
            workforce and your investment.
          </p>
          <p className="text-lg font-semibold tracking-wider">
            <span className="font-semibold text-2xl"> Timely Completion </span>
            <br />
            We understand the importance of deadlines and strive to complete
            projects on time, every time.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default Choose;
