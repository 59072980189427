import Photo from "../Reuse/Photo";
import Projects from "./Projects";

const Work = () => {

  const url =
    "https://plus.unsplash.com/premium_photo-1671808062726-2a7ffcd6109e?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y29uc3RydWN0aW9ufGVufDB8fDB8fHww";
   

  return (
    <div>
      <Photo height={"min-h-[60vh]"} url={url} title={" Our Works"} />
      <Projects/>
    </div>
  );
}

export default Work
