import React from 'react'

const Card = ({ title, content, image, opacity, text, click }) => {
  return (
    <div 
    onClick={click && click}
    className="bg-gray-800 cursor-pointer relative h-fit flex flex-col gap-3 rounded bg-white shadow-lg">
      <div className="w-full h-full">
        <img
          src={image}
          alt={title}
          className="w-full h-full rounded-t object-contain object-center"
        />
      </div>
      {title && (
        <div className="flex flex-col gap-3 p-3">
          <h2 className="text-3xl font-bold tracking-wider">{title}</h2>
          <p className="font-semibold">{content.slice(0, 150)}...</p>
        </div>
      )}
      <span
        className={`absolute flex justify-center items-center top-0 w-full h-full hover:opacity-50 ${opacity} bg-black transition-all duration-500 ease-in-out`}
      >
        {text && (
          <span className="text-3xl text-teal-300 opacity-100"> {text} </span>
        )}
      </span>
    </div>
  );
};

export default Card
