import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />
      <main className="w-full min-h-screen bg-[#f2f3f5]">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
