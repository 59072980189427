import React, {useState} from 'react';
import {motion} from "framer-motion";
import { fadeIn } from '../../motion/motion';
import Dialogue from '../Reuse/Dialogue';


const Quotation = () => {

  const [open, setOpen] = useState(false);

  return (
    <div className="container mx-auto py-12 md:py-24">
      <motion.div
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.35, 1)}
        className="flex md:w-2/3 px-4 mx-auto justify-start items-start flex-col gap-3 md:items-center"
      >
        <h2 className="text-3xl font-bold tracking-wider md:text-center">
          Building Excellence with ALLCON
        </h2>
        <p className="text-lg font-semibold tracking-wider md:text-center">
          Allcon LLC stands at the forefront of the construction industry,
          offering unparalleled building services.
        </p>
        <p className="text-lg font-semibold tracking-wider md:text-center">
          Providing expert construction Management Systems and Civil/Hydraulic
          Engineering ​services in the wisconcin.
        </p>
        <p className="text-lg font-semibold tracking-wider md:text-center">
          Our commitment to quality, sustainability, and client satisfaction
          makes us your ideal partner for any construction project.
        </p>
        <button
          onClick={() => setOpen(!open)}
          className="text-white font-semibold mt-4 p-3 bg-[#09091c] transition-all ease-in-out duration-300 border-indigo-600 hover:bg-teal-400 border px-6 rounded"
        >
          Get Your Consultation
        </button>
      </motion.div>
      {open && <Dialogue click={() => setOpen(!open)} />}
    </div>
  );
}

export default Quotation
