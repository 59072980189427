import { motion } from "framer-motion";
import { fadeIn } from "../../motion/motion";

const Photo = ({ url, height, title }) => {
  return (
    <div className="w-full bg-black h-auto">
      <div
        className={`w-full relative py-8  ${height} flex items-center justify-center`}
      >
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover opacity-50 bg-black"
          style={{ backgroundImage: `url(${url})` }}
        >
          <span className="w-full h-full absolute left-0 opacity-25 bg-black"></span>
        </div>
        <motion.div
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true }}
          variants={fadeIn("up", "", 0.25, 1)}
          className="flex relative px-8 flex-col items-start justify-center"
        >
          <h1 className="text-white font-bold text-3xl sm:text-5xl text-left">
            {title}
          </h1>
        </motion.div>
      </div>
    </div>
  );
};

export default Photo;
