import React from 'react';
import {motion} from "framer-motion";
import {fadeIn} from "../../motion/motion";

const ServiceList = () => {
  return (
    <div className="container mx-auto py-8 px-4">
      <motion.div
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.25, 1)}
        className="flex w-full px-4 rounded-lg justify-center text-white text-3xl items-center py-8 bg-[#09091c] mt-2"
      >
        <span className="text-center tracking-wider font-semibold">
          24/7 Emergency Service
          <br /> Contact Us 0986353739
        </span>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true }}
        variants={fadeIn("up", "", 0.25, 1)}
        className="grid grid-cols-1 gap-8 md:grid-cols-2 py-8 md:py-24"
      >
        <div className="flex flex-1 gap-3 order-2 md:order-1 flex-col">
          <h2 className="text-3xl font-bold tracking-wider">
            Expert Guidance at Every Step
          </h2>
          <p className="text-lg font-semibold tracking-wider">
            For clients who need guidance in the complex world of construction,
            ALLCON offers consultancy and advisory services. We provide expert
            advice on project feasibility, cost analysis, regulatory compliance,
            and sustainable building practices, ensuring our clients make
            informed decisions at every stage of their project.
          </p>
          <h2 className="text-3xl font-bold tracking-wider">
            Laying Foundations for the Future
          </h2>
          <p className="text-lg font-semibold tracking-wider">
            Infrastructure development is critical to societal progress, and at
            ALLCON, we take this responsibility seriously. Our portfolio
            includes a range of infrastructure projects like bridges, roads, and
            public utilities. We employ advanced technologies and sustainable
            practices to ensure these structures not only serve their immediate
            purpose but also contribute to long-term community development.
          </p>
        </div>
        <div className="flex order-1 md:order-2 flex-1 flex-col">
          <img
            className="w-full h-full object-containe object-cover rounded"
            src="https://cdn.pixabay.com/photo/2016/02/01/21/15/excavator-1174428_1280.jpg"
            alt="alcon llc staff"
          />
        </div>
      </motion.div>
    </div>
  );
}

export default ServiceList
