import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, EffectFade } from "swiper/modules";
import { motion } from "framer-motion";
import { fadeIn } from "../../motion/motion";
import Dialogue from "../Reuse/Dialogue";

export default function Slider() {

  const [open, setOpen] = useState(false);

  const lists = [
    {
      url: "https://cdn.pixabay.com/photo/2014/11/29/08/11/house-demolitions-549667_1280.jpg",
    },
    {
      url: "https://cdn.pixabay.com/photo/2017/10/28/15/44/new-home-2897352_1280.jpg",
    },
    {
      url: "https://cdn.pixabay.com/photo/2021/08/30/18/33/worker-6586718_1280.jpg",
    },
  ];

  return (
    <div className="relative w-full bg-black h-auto" id="home">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay, EffectFade]}
        className="mySwiper"
      >
        {lists.map((list, index) => {
          const { url } = list;
          return (
            <SwiperSlide key={index}>
              <div className="w-full cursor-pointer relative h-auto min-h-[85vh]  flex items-center justify-center">
                <div
                  className="absolute top-0 w-full h-full bg-center bg-cover opcaity-75 bg-black"
                  style={{ backgroundImage: `url(${url})` }}
                >
                  <span className="w-full h-full absolute left-0 opacity-75 bg-black"></span>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="container absolute w-[90%] md:w-[80%] left-[5%] z-10  top-1/4 md:pt-8 transform px-4">
        <motion.div
          initial={"hidden"}
          whileInView={"show"}
          viewport={{ once: true }}
          variants={fadeIn("up", "", 0.75, 1)}
          className="w-full justify-start items-start flex flex-col"
        >
          <div className="flex flex-col gap-2 items-start">
            <h1 className="text-white font-bold text-3xl sm:text-5xl text-left">
              ALLCON
            </h1>
            <p className=" text-2xl font-normal tracking-wider max-w-xl text-white">
              Comprehensive Building Solutions Partner and Female owned general
              contracting firm. You don't invest in business you invest in
              people. Our people make our business. Let's us craft a design for
              you.
            </p>
            <button
              onClick={() => setOpen(true)}
              className="text-white w-fit font-semibold mt-4 p-3 bg-[#09091c] transition-all ease-in-out duration-300 border-indigo-600 hover:bg-teal-400 border px-6 rounded"
            >
              Get Your Consultation
            </button>
          </div>
        </motion.div>
      </div>
      {open && <Dialogue click={() => setOpen(!open)} />}
    </div>
  );
}
