import { blogLists } from "../../constant"
import React, { useState, useEffect } from "react";
import Photo from "../Reuse/Photo";

const BlogItem = React.memo(({ item }) => {
  const { title, image, text, date } = item;

  return (
    <>
      <section>
        <Photo title={title} url={image} height={"min-h-[60vh]"} />
      </section>

      {/* Description Section */}

      {/* key value pair using contenct as an object */}
      <section>
        <div className="container mx-auto px-4">
          <div className="flex flex-col items-start gap-2 py-8">
            <span className="w-full text-right">{date}</span>
            {text.map((ct, index) => {
              const { title, body } = ct;
              return (
                <div
                  key={index}
                  className="flex  mx-auto flex-col h-auto justify-center items-start gap-2 p-2"
                >
                  <h2 className="leading px-6 py-4 flex gap-2 justify-center font-black text-3xl">
                    {title}
                  </h2>
                  <p className="text-lg px-6 max-w-4xl">{body}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
});

export default function Page() {
  const location = window.location.href;
  let routes = location.split("/");
  routes = routes[routes.length - 1];
  console.log(routes);
  const [data, setData] = useState();
  // Fetch data on mount
  useEffect(() => {
    setData(blogLists.filter((li) => li.id === routes));
  }, [routes]);

  return (
    <div className="mx-auto bg-[#f4f4f4]">
      {data?.map((item, index) => <BlogItem key={index} item={item} />)}
    </div>
  );
}

